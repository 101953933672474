<template>
    <div class="chooseCompany" ref="chooseCompany" id="chooseCompanyId">
        <step :stepNum="stepNum"></step>
        <!-- 企业基础信息 -->

        <div class="companyInfo" ref="companyInfo">
            <div class="top">
                <div class="left">
                    <div>
                        <img src="../../../../assets/pc/images/matching/qiye.png" alt="" srcset="" />
                    </div>
                    <div class="entityName">
                        <div>{{ entityName }}</div>
                        <div class="entityNameEdit" @click="jumpEdit">修改基础信息</div>
                    </div>
                </div>
                <div class="right">
                    <!-- <div @click="checkLabelGroup">查看已选标签</div> -->
                    <div>
                        <span>匹配项目</span>
                        <span class="allFormalNum" @click="checkMachProject">{{ allFormalNum }}</span>
                        <i class="el-icon-arrow-right"></i>
                    </div>
                    <div>匹配进度</div>
                    <div>
                        <el-progress :stroke-width="12" :percentage="matchProgress" color="#156ED0"></el-progress>
                    </div>
                </div>
            </div>
            <div class="footer">
                <div>
                    <div>基础标签</div>
                    <div ref="targetBasic">
                        <span v-for="(item, index) in basicTag" :key="index"
                            style="margin-right:10px;margin-bottom:10px" class="span-label">
                            {{ item }}
                        </span>
                    </div>
                </div>
                <div>
                    <div>选项标签</div>
                    <div ref="targetOption">
                        <span v-for="(item, index) in optionTag" :key="index"
                            style="margin-right:10px;margin-bottom:10px" class="span-label">{{ item }}</span>
                    </div>
                </div>
            </div>
            <div class="base" @click="checkAll" v-if="showBtnBasic || showBtnOption">查看全部</div>
        </div>

        <!-- 标签选择 -->
        <div class="labelGroup" ref="labelGroup">
            <div v-for="(item, index) in conditionList" :key="index" class="labelGroup-items"
                :ref="`labelGroup${index}`" :id="`labelGroupId${index}`"
                :style="item.mandatory ? 'border: 1px solid rgba(187, 187, 187, .5);' : 'border: 1px solid #E36057;'">
                <div>第{{ index + 1 + stepProjectNum }}项<span v-if="item.type == 2 || item.type == 3"
                        style="margin-left:18px">「{{ item.subtitle }}」</span><span v-if="!item.mandatory"
                        style="color:#E36057;margin-left:14px">必选项未选</span></div>
                <div class="mandatory labelGroup-items-title" v-if="item.type == 1">{{ item.note }}</div>
                <div v-if="item.type == 3 || item.type == 2" class="mandatory labelGroup-items-title">{{
                        item.groupName
                }}</div>
                <div style="margin-left:20px">
                    <!-- type = 1 判断有无折叠 -->
                    <div v-if="item.type == 1">
                        <div v-for="(v, i) in item.tagList" :key="i" v-show="!item.hideGroupIndexCopy[i]">
                            <el-checkbox-group v-model="checkListCategory[index]" class="checkboxGroup">
                                <div v-for="(m, n) in v" :key="n" style="display:inline-block;width:33%">
                                    <el-tooltip class="item-tooltip" effect="light" placement="bottom"
                                        :disabled="!m.note">
                                        <div slot="content">{{ m.note }}</div>
                                        <el-checkbox :label="m.tagId" :disabled="item.blankOptions"
                                            v-show="item.type == 1" @change="handleChooseGroup(index, i, n, m)"
                                            style="margin-right:20px;margin-bottom:20px">
                                            {{ m.tagName }}
                                        </el-checkbox>
                                    </el-tooltip>
                                </div>
                            </el-checkbox-group>
                        </div>
                    </div>
                    <div v-else>
                        <div v-for="(v, i) in item.tagList" :key="i">
                            <el-checkbox-group v-model="checkListCategory[index]" class="checkboxGroup">
                                <el-checkbox :label="v.tagId" class="checkbox-item" :disabled="item.blankOptions"
                                    :style="i < 1 ? 'width:98%;margin-top:20px' : 'width:98%'"
                                    @change="handleChooseGroupType(index, i, v)">
                                    {{ v.originalText }}</el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </div>
                </div>
                <div class="folding" v-if="item.hasHideGroup && item.type == 1" @click="handleFold(item, index)">
                    <i :class="item.arrow == 'up' ? 'el-icon-bottom' : 'el-icon-top'"></i>
                    <span>{{ item.foldName }}</span>
                </div>
                <div class="blankOptions">
                    <el-checkbox v-model="item.blankOptions" @change="changeBlankOptions(index, item)">
                        以上选项都不符合</el-checkbox>
                </div>
                <div class="corner" v-if="checkListCategory[index].length > 0 || item.blankOptions">
                    <i class="el-icon-check"></i>
                </div>
            </div>
        </div>

        <div style="text-align: center">
            <el-button round style="
                width: 143px;
                height: 43px;
                margin-top: 38px;
                background: #156ED0;
                color: #fff;
                " :loading="loading" @click="submit">提交</el-button>
        </div>
        <div style="text-align: center" id="btnId">
            <el-button round style="width: 143px; height: 43px; margin-top: 16px" @click="goBack" :loading="btnReturnLoading">返回</el-button>
        </div>

        <!-- 查看匹配标签弹窗 -->

        <el-dialog title="匹配标签" :visible.sync="matchLabelGroupVisible" width="30%" class="spec-dialog">
            <div style="color: rgba(0, 0, 0, 0.8); font-size: 14px; margin-bottom: 12px">
                基础标签
            </div>
            <div style="30px">
                <el-tag v-for="(item, index) in basicTag" :key="index" size="mini"
                    style="margin-right: 10px; margin-bottom: 10px">{{ item }}</el-tag>
            </div>
            <div style="color: rgba(0, 0, 0, 0.8); font-size: 14px; margin-bottom: 12px">
                选项标签
            </div>
            <div>
                <el-tag v-for="(item, index) in optionTag" :key="index" size="mini"
                    style="margin-right: 10px; margin-bottom: 10px">{{ item }}</el-tag>
            </div>

        </el-dialog>

        <!-- 匹配项目弹窗  -->

        <el-dialog title="匹配项目" :visible.sync="matchProjectVisible" width="80%">
            <div style="padding-bottom: 107px">
                <el-table :data="matchProjectData" :header-cell-style="{ 'text-align': 'center' }">
                    <el-table-column label="序号" type="index" align="center" width="80"></el-table-column>
                    <el-table-column label="标题" prop="subtitle" align="left" width="420">
                        <template slot-scope="scope">
                            <span :class="scope.row.remain >= 0 ? 'remain' : 'hasas'">
                            {{ scope.row.remain > 0 ? '【剩余' + scope.row.remain + '天】' : scope.row.remain == 0 ? '【今天截止】' : '【已截止】'
                            }}</span>
                            <span>{{ scope.row.subtitle }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="主管部门" prop="deptName" align="center"></el-table-column>
                    <el-table-column label="政策级别" prop="zcJibie" align="center">
                        <template slot-scope="scope">
                            <span>{{
                                    scope.row.zcJibie == 1
                                        ? "中央级"
                                        : scope.row.zcJibie == 2
                                            ? "省级"
                                            : scope.row.zcJibie == 3
                                                ? "市级"
                                                : scope.row.zcJibie == 4
                                                    ? "区级"
                                                    : "镇街级"
                            }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="扶持方式" prop="supportModeList" align="center">
                        <template slot-scope="scope">
                            <div v-for="(item, index) in scope.row.supportModeList" :key="index"
                                style="display: flex; flex-wrap: wrap; justify-content: center">
                                <div v-if="item.tagName == '资金扶持'" class="zjfc">
                                    {{ item.tagName }}
                                </div>
                                <div v-if="item.tagName == '称号认定'" class="chrd">
                                    {{ item.tagName }}
                                </div>
                                <div v-if="item.tagName == '股权投资'" class="gqtz">
                                    {{ item.tagName }}
                                </div>
                                <div v-if="item.tagName == '减税降费'" class="jsjf">
                                    {{ item.tagName }}
                                </div>
                                <div v-if="item.tagName == '金融扶持'" class="jrfc">
                                    {{ item.tagName }}
                                </div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="checkDetail(scope.row)">查看详情</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="float: right;margin-top: 32px;">
                    <el-pagination small layout="prev, pager, next" :total="total" :current-page="pageNo"
                        @current-change="handleCurrentChange">
                    </el-pagination>
                </div>
            </div>

        </el-dialog>

        <!-- 锚点定位 -->
        <div class="anchor" ref="anchor" :style="{ left: (screenW - 1190) / 2 + 1190 + + 50 + 'px' }">
            <div class="anchor-line" ref="anchorLine"></div>
            <div ref="anchorList">
                <div class="anchorList-items" @click="scrollOrigin">回到顶部</div>
                <div v-for="(item, index) in anchorList" :key="index" class="anchorList-items-options"
                    :class="item.scrollIndex ? 'item-middle-show' : ''" @click="clickAnchor(index)">
                    <span class="anchor-circle" v-if="item.scrollIndex"></span>
                    <span
                        :style="item.scrollIndex ? 'color:#156ED0;width:50px' : 'color:rgba(99, 99, 99, 100);width:50px'">{{
                                item.name
                        }}</span>
                    <i class="el-icon-check" style="color:#156ED0;font-size:15px;margin-left:5px"
                        v-if="item.checked"></i>
                </div>
                <div class="anchorList-items " @click="scrollBottom">置底</div>
            </div>
            <div class="top-circle"></div>
            <div class="bottom-circle"></div>
        </div>
    </div>
</template>

<script>
import step from '../components/index.vue';
import { getMemberId } from '@/assets/public/utils/token';
export default {
  components: {
    step
  },
  data () {
    return {
      stepNum: 2,
      entityName: '',
      allFormalNum: '256',
      matchProgress: 30,
      basicTag: [],
      optionTag: [],
      conditionList: [],
      loading: false,
      step: 0,
      userId: '',
      pageNo: 1,
      pageSize: 10,
      matchLabelGroupVisible: false,
      matchProjectVisible: false,
      matchProjectData: [],
      total: 0,
      stepProjectNum: 0,
      showBtnBasic: false,
      showBtnOption: false,
      anchorList: [],
      localData: {},
      checkListCategory: [ [] ],
      groupIscheckList: [],
      screenW: 0,
      screenH: 0,
      offsetH: 0,
      // loading: false,
      btnReturnLoading: false
    };
  },
  watch: {
    screenW (val) {

    },
    screenH: {
      handler (val) {
        
      },
      immediate: true,
      deep: true
    }
  },
  mounted () {
    // this.userId = JSON.parse(localStorage.getItem('parameter')).userId;

    if (localStorage.getItem('localData')) {
      const res = JSON.parse(localStorage.getItem('localData'));
      this.localData = res;
      this.basicTag = res.basicTag;
      this.optionTag = res.optionTag;
      this.allFormalNum = res.allFormalNum;
      this.matchProgress = parseInt(res.matchProgress * 100);
      // let c = []
      this.checkListCategory = res.checkListCategory;
      res.conditionList.forEach((v, i) => {
        // v.chooseGroup = v.chooseGroup;
        this.groupIscheckList.push(i);
        // c.push({ name: '第' + (i + 1 + res.stepProjectNum) + '项', active: false ,scrollIndex:null,checked:false})
        v.hideGroupIndexCopy = v.hideGroupIndex;
        v.foldName = '展开更多选项';
        v.arrow = 'up';
        v.mandatory = true;
      });
      this.anchorList = res.anchorList;
      this.conditionList = res.conditionList;
      this.step = res.step;
      this.stepProjectNum = res.stepProjectNum;
      this.loading = false;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 800;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.$nextTick(() => {
        this.$refs.anchorLine.style.height = (26 + this.$refs.anchorList.offsetHeight) + 'px';
        this.$refs.anchorLine.style.background = 'rgba(0, 0, 0, .3)';
        this.$refs.anchorLine.style.width = 1 + 'px';
        this.$refs.anchorLine.style.position = 'relative';
      });
      this.entityName = res.entityName;
    } else {
      const res = JSON.parse(localStorage.getItem('paramsData'));
      this.localData = res;
      this.allFormalNum = res.allFormalNum;
      this.matchProgress = parseInt(res.matchProgress * 100);
      const c = [];
      res.conditionList.forEach((v, i) => {
        v.chooseGroup = [];
        this.checkListCategory[i] = [];
        this.groupIscheckList.push(i);
        c.push({ name: '第' + (i + 1 + this.stepProjectNum) + '项', active: false, scrollIndex: false, checked: false });
        v.hideGroupIndexCopy = v.hideGroupIndex;
        v.foldName = '展开更多选项';
        v.arrow = 'up';
        v.mandatory = true;
      });
      this.anchorList = c;
      this.conditionList = res.conditionList;
      this.step = this.step + 1;
      let top = document.documentElement.scrollTop || document.body.scrollTop;
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 800;
        if (top <= 0) {
          clearInterval(timeTop);
        }
      }, 10);
      this.$nextTick(() => {
        this.$refs.anchorLine.style.height = (26 + this.$refs.anchorList.offsetHeight) + 'px';
        this.$refs.anchorLine.style.background = 'rgba(0, 0, 0, .3)';
        this.$refs.anchorLine.style.width = 1 + 'px';
        this.$refs.anchorLine.style.position = 'relative';
        this.offsetH = document.querySelector('#labelGroupId0').getBoundingClientRect().top;
      });

      this.entityName = this.$route.query.entityName;
    }

    this.checkLabelGroup();
    window.addEventListener('scroll', this.handleScroll, true);

    this.screenW = document.body.clientWidth;

    window.onresize = () => {
      return (() => {
        this.screenW = document.body.clientWidth;
      })();
    };
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    /* 基础信息及标签选项 */
    async getInfo (params) {
      this.loading = true;
      const { data: res } = await this.$http({
        method: 'POST',
        url: this.$baseURL + '/pcp/projectmatch/new',
        data: params,
        headers: { 'Content-Type': 'application/json' }
      });
      this.loading = false;
      if (res.code === 501) {
        this.stepProjectNum = this.stepProjectNum - this.conditionList.length;
        this.$message.error('网络繁忙');
        // this.loading = false
      } else if (res.code === 200) {
        if (!res.data.showResult) {
          this.localData = res.data;
          this.allFormalNum = res.data.allFormalNum;
          this.matchProgress = parseInt(res.data.matchProgress * 100);
          const c = [];
          res.data.conditionList.forEach((v, i) => {
            v.chooseGroup = [];
            this.checkListCategory[i] = [];
            this.groupIscheckList.push(i);
            c.push({ name: '第' + (i + 1 + this.stepProjectNum) + '项', active: false, scrollIndex: false, checked: false });
            v.hideGroupIndexCopy = v.hideGroupIndex;
            v.foldName = '展开更多选项';
            v.arrow = 'up';
            v.mandatory = true;
          });
          this.anchorList = c;
          this.conditionList = res.data.conditionList;
          this.step = this.step + 1;
          // this.loading = false
          let top = document.documentElement.scrollTop || document.body.scrollTop;
          if (this.step > 1) {
            this.checkLabelGroup();
          }
          // 实现滚动效果
          const timeTop = setInterval(() => {
            document.body.scrollTop = document.documentElement.scrollTop = top -= 800;
            if (top <= 0) {
              clearInterval(timeTop);
            }
          }, 10);
          this.$nextTick(() => {
            this.$refs.anchorLine.style.height = (26 + this.$refs.anchorList.offsetHeight) + 'px';
            this.$refs.anchorLine.style.background = 'rgba(0, 0, 0, .3)';
            this.$refs.anchorLine.style.width = 1 + 'px';
            this.$refs.anchorLine.style.position = 'relative';
            this.offsetH = document.querySelector('#labelGroupId0').getBoundingClientRect().top;
          });
        } else {
          const res1 = {
            formalList: res.data.formalList,
            entityName: this.entityName,
            recordId: res.data.recordId
          };
          const parameter = JSON.stringify(res1);
          this.$router.push({
            path: 'resultCompany',
            query: { parameter: parameter }
          });
        }
        // this.loading = false
      } else if (res.code !== 200 && res.code !== 501) {
        this.$message.error('提交失败');
        // this.loading = false
      }
    },
    /* 跳转基础信息页面 */
    jumpEdit () {
      this.localData.entityName = this.entityName;
      this.localData.step = this.step;
      this.localData.userId = getMemberId();
      this.localData.stepProjectNum = this.stepProjectNum;
      this.localData.checkListCategory = this.checkListCategory;
      this.localData.anchorList = this.anchorList;
      localStorage.setItem('localData', JSON.stringify(this.localData));
      this.$router.push({
        path: 'infoCompany',
        query: {
          entityName: this.entityName,
          navigateTo: '1'
        }
      });
    },
    /* 弹窗内匹配项目 */
    async getStepProject () {
      const { data: res } = await this.$http({
        method: 'GET',
        url:
                    this.$baseURL +
                    '/pcp/projectmatch/new/getStepProject?step=' +
                    this.step +
                    '&userId=' +
                    getMemberId() +
                    '&pageNo=' +
                    this.pageNo +
                    '&pageSize=' +
                    this.pageSize
      });
      if (res.code !== 200) return this.$message.error('匹配项目数据获取失败');
      this.matchProjectData = res.data.list;
      this.total = res.data.totalSize;
    },

    /* 多选框选中 */
    handleChooseGroup (index, i, n, m) {
      const flag = this.checkListCategory[index].some(v => {
        return m.tagId === v;
      });
      this.conditionList[index].tagList[i][n].isChecked = !!flag;
      this.anchorList[index].checked = this.checkListCategory[index].length > 0;
      this.conditionList[index].mandatory = true;
    },
    /* 多选框选中 */
    handleChooseGroupType (index, i, group) {
      const flag = this.checkListCategory[index].some(v => {
        return group.tagId === v;
      });
      this.conditionList[index].tagList[i].isChecked = !!flag;
      this.anchorList[index].checked = this.checkListCategory[index].length > 0;
      this.conditionList[index].mandatory = true;
    },

    /* 返回上一步 */
    async getPreStep () {
      if (this.step < 1) {
        this.$router.push({
          path: 'infoCompany',
          query: {
            entityName: this.entityName,
            navigateTo: '1'
          }
        });
      } else {
        this.btnReturnLoading = true;
        const { data: res } = await this.$http({
          method: 'GET',
          url: this.$baseURL + '/pcp/projectmatch/new/preStep?step=' + this.step + '&userId=' + getMemberId()
        });
        this.btnReturnLoading = false;
        if (res.code !== 200) {
          return this.$message.error('返回上一步数据请求失败');
        }
        this.localData = res.data;
        localStorage.removeItem('localData');
        this.allFormalNum = res.data.allFormalNum;
        this.matchProgress = parseInt(res.data.matchProgress * 100);
        const c = [];
        // 返回上一步的回显
        res.data.conditionList.forEach((v, i) => {
          c.push({ name: '第' + (i + 1 + this.stepProjectNum - res.data.conditionList.length) + '项', active: false, scrollIndex: false });
          v.hideGroupIndexCopy = v.hideGroupIndex;
          v.foldName = '展开更多选项';
          v.arrow = 'up';
          v.mandatory = true;
          v.chooseGroup = [];
          v.tagList.forEach(item => {
            if (v.type === 1) {
              item.forEach(m => {
                if (m.isChecked) {
                  this.checkListCategory[i].push(m.tagId);
                }
              });
            } else {
              if (item.isChecked) {
                this.checkListCategory[i].push(item.tagId);
              }
            }
          });
        });
        res.data.conditionList.forEach((v, i) => {
          if (v.mandatory) {
            c[i].checked = true;
          } else {
            c[i].checked = false;
          }
        });
        this.anchorList = c;

        this.conditionList = res.data.conditionList;

        this.stepProjectNum = this.stepProjectNum - this.conditionList.length;
        let top = document.documentElement.scrollTop || document.body.scrollTop;
        this.$http({
          method: 'GET',
          url: this.$baseURL + '/pcp/projectmatch/new/getChooseTag?memberId=' + getMemberId()
        }).then((res) => {
          if (res.data.code !== 200) return this.$message.error('获取已选标签失败');
          // this.matchLabelGroupVisible = !this.matchLabelGroupVisible;
          this.basicTag = res.data.data.basicTag;
          this.optionTag = res.data.data.optionTag;
        });

        // 实现滚动效果
        const timeTop = setInterval(() => {
          document.body.scrollTop = document.documentElement.scrollTop = top -= 800;
          if (top <= 0) {
            clearInterval(timeTop);
          }
        }, 10);

        this.$nextTick(() => {
          this.$refs.anchorLine.style.height = (26 + this.$refs.anchorList.offsetHeight) + 'px';
          this.$refs.anchorLine.style.background = 'rgba(0, 0, 0, .3)';
          this.$refs.anchorLine.style.width = 1 + 'px';
          this.$refs.anchorLine.style.position = 'relative';
          this.offsetH = document.querySelector('#labelGroupId0').getBoundingClientRect().top;
        });
        // this.btnReturnLoading = false
      }
    },

    /* 已选标签 */
    checkLabelGroup () {
      this.showBtnBasic = true;
      this.$http({
        method: 'GET',
        url: this.$baseURL + '/pcp/projectmatch/new/getChooseTag?memberId=' + getMemberId()
      }).then((res) => {
        if (res.data.code !== 200) return this.$message.error('获取已选标签失败');
        this.basicTag = res.data.data.basicTag;
        this.optionTag = res.data.data.optionTag;
        this.localData.basicTag = res.data.data.basicTag;
        this.localData.optionTag = res.data.data.optionTag;
        this.$nextTick(() => {
          if (this.$refs.targetBasic.offsetHeight > 34) {
            this.$refs.targetBasic.style.height = 20 + 'px';
            this.$refs.targetBasic.style.overflow = 'hidden';
            this.showBtnBasic = true;
          } else {
            this.showBtnBasic = false;
            if (this.$refs.targetOption.offsetHeight > 60) {
              this.$refs.targetOption.style.height = 60 + 'px';
              this.$refs.targetOption.style.overflow = 'hidden';
              this.showBtnOption = true;
            } else {
              this.showBtnOption = false;
            }
          }
        });
      });
    },

    /* 查看全部标签 */
    checkAll () {
      this.matchLabelGroupVisible = !this.matchLabelGroupVisible;
    },

    /* 查看匹配项目 */
    checkMachProject () {
      this.matchProjectVisible = !this.matchProjectVisible;
      this.pageNo = 1;
      this.getStepProject();
    },

    /* 查看匹配项目弹窗分页 */
    handleCurrentChange (e) {
      this.pageNo = e;
      this.getStepProject();
    },

    /* 查看匹配项目详情 */
    checkDetail (rows) {
      const id = rows.zhengceid;
      const inputId = rows.id;
      // if (this.$baseURL === 'https://javatest.po-o.cn') {
      //   window.open('https://ctxtn.po-o.cn/formaldetails/jiedu?id=' + id + '&inputId=' + inputId);
      // } else {
      //   window.open('https://po-o.cn/formaldetails/jiedu?id=' + id + '&inputId=' + inputId);
      // }
      window.open(`${process.env.VUE_APP_HTTP_URL}/formaldetails/jiedu?id=` + id + '&inputId=' + inputId);
    },

    /* 监听不符合多选框勾选事件 */
    changeBlankOptions (index, row) {
      this.checkListCategory[index] = [];
      this.conditionList[index].mandatory = true;
      if (row.type === 1) {
        this.conditionList[index].tagList.forEach(item => {
          item.forEach(v => {
            v.isChecked = false;
          });
        });
      } else {
        this.conditionList[index].tagList.forEach(item => {
          item.isChecked = false;
        });
      }

      this.anchorList[index].checked = row.blankOptions;
    },

    /* 提交 */
    submit () {
      this.loading = true;
      let flag = false;
      flag = this.conditionList.every((v, i) => {
        if (v.blankOptions) {
          return v.blankOptions;
        } else {
          return this.checkListCategory[i].length > 0;
        }
      });
      this.conditionList.forEach((v, i) => {
        if (!v.blankOptions) {
          if (this.checkListCategory[i].length > 0) {
            v.mandatory = true;
          } else {
            v.mandatory = false;
          }
        } else {
          v.mandatory = true;
        }
      });
      if (!flag) {
        this.loading = false;
        this.$message.error('必选项未选');
        const re = this.anchorList;
        const findIndex = re.findIndex(item => !item.checked);
        document.querySelector('#labelGroupId' + findIndex).scrollIntoView(true);
      } else {
        this.loading = true;
        localStorage.removeItem('localData');
        const params = {
          entityName: this.entityName,
          userId: getMemberId(),
          step: this.step + 1,
          conditionList: this.conditionList
        };
        this.stepProjectNum = this.stepProjectNum + this.conditionList.length;
        this.getInfo(params);
        this.$refs.targetBasic.style.height = 'auto';
        this.$refs.targetBasic.style.overflow = 'auto';
        this.$refs.targetOption.style.height = 'auto';
        this.$refs.targetOption.style.overflow = 'auto';
      }
    },

    /* 返回 */
    goBack () {
      this.step = this.step - 1;
      this.getPreStep();
    },

    handleScroll () {
      const that = this;
      const scrolltop = document.documentElement.scrollTop || document.body.scrollTop;
      scrolltop > 30 ? (that.gotop = true) : (that.gotop = false);
      that.$nextTick(() => {
        that.anchorList.forEach(v => {
          v.scrollIndex = false;
        });

        that.anchorList.forEach((v, i) => {
          const offsetTop = document.querySelector('#labelGroupId' + i).offsetTop;
          if (scrolltop > offsetTop) {
            v.active = true;
            v.scrollIndex = true;
          } else {
            v.active = false;
            v.scrollIndex = false;
          }
        });
        const result = that.anchorList.reverse();
        const keyIndex = result.findIndex(item => item.scrollIndex);
        result.forEach((v, i) => { i == keyIndex ? v.scrollIndex = true : v.scrollIndex = false; });
        that.anchorList = result.reverse();
      });
    },
    clickAnchor (index) {
      document.querySelector('#labelGroupId' + index).scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
      });
    },
    handleFold (item, index) {
      this.$forceUpdate();
      if (item.arrow === 'up') {
        this.conditionList[index].arrow = 'down';
        this.conditionList[index].foldName = '收起';
        const arr = [];
        this.conditionList[index].hideGroupIndexCopy.forEach(v => {
          arr.push(false);
        });
        this.conditionList[index].hideGroupIndexCopy = arr;
      } else {
        this.conditionList[index].arrow = 'up';
        this.conditionList[index].foldName = '展开更多选项';
        this.conditionList[index].hideGroupIndexCopy = this.conditionList[index].hideGroupIndex;
      }
    },
    // 回到顶部
    scrollOrigin () {
      document.querySelector('#chooseCompanyId').scrollIntoView(true);
    },
    // 置底
    scrollBottom () {
      document.querySelector('#btnId').scrollIntoView(true);
    }
  }
};
</script>

.<style lang="less" scoped>
.chooseCompany {
    padding-top: 20px;

    // perspective: 100px;
    .companyInfo {
        background-color: rgba(243, 248, 255, 67);
        color: rgba(16, 16, 16, 100);
        font-size: 14px;
        font-family: Arial;
        border: 1px solid rgba(226, 237, 247, 100);
        padding: 25px 22px;

        .top {
            width: 100%;
            display: flex;
            align-items: center;

            .left {
                width: 500px;
                display: flex;
                align-items: center;

                img {
                    width: 20px;
                    height: 20px;
                }

                .entityName {
                    color: rgba(51, 51, 51, 100);
                    font-size: 18px;
                    font-family: SourceHanSansSC-medium;
                    font-weight: bold;
                    margin-left: 11px;
                    margin-right: 11px;
                    display: flex;
                    align-items: center;

                    .entityNameEdit {
                        color: rgba(48, 120, 240, 100);
                        font-size: 12px;
                        font-weight: 400;
                        // margin-top: 10px;
                        margin-left: 12px;
                        width: 100px;
                    }
                }
            }

            .right {
                flex: 1;
                display: flex;
                align-items: center;
                margin-left: 60px;

                // div:nth-child(1) {
                //     color: rgba(48, 120, 240, 100);
                //     font-size: 12px;
                //     font-family: SourceHanSansSC-regular;
                //     margin-right: 25px;
                // }

                div:nth-child(1) {
                    color: rgba(0, 0, 0, 0.8);
                    font-size: 14px;
                    font-family: SourceHanSansSC-regular;
                    display: flex;
                    align-items: center;

                    .allFormalNum {
                        color: rgba(0, 0, 0, 0.8);
                        // color: #156ED0;
                        font-size: 20px;
                        font-family: SourceHanSansSC-regular;
                        margin-left: 20px;
                        margin-right: 10px;
                    }
                }

                div:nth-child(2) {
                    margin-left: 37px;
                    margin-right: 8px;
                }

                div:nth-child(3) {
                    width: 230px;
                }
            }
        }

        .footer {
            margin-top: 30px;

            div:nth-child(1) {
                margin-bottom: 20px;
                color: rgba(121, 121, 121, 100);
                font-size: 12px;
                font-family: SourceHanSansSC-regular;
                display: flex;

                div:nth-child(1) {
                    margin-right: 14px;
                    width: 50px;
                    flex: 1;
                }

                div:nth-child(2) {
                    width: 980px;
                    display: flex;
                    flex-wrap: wrap;
                    // height: 20px;
                    // overflow: hidden;
                }
            }

            div:nth-child(2) {
                color: rgba(121, 121, 121, 100);
                font-size: 12px;
                font-family: SourceHanSansSC-regular;
                display: flex;

                div:nth-child(1) {
                    margin-right: 14px;
                    width: 50px;
                    flex: 1;
                }

                div:nth-child(2) {
                    width: 980px;
                    display: flex;
                    flex-wrap: wrap;
                    // height: 60px;
                    // overflow: hidden;
                }
            }
        }

        .base {
            text-align: center;
            margin-top: 18px;
            color: rgba(137, 158, 192, 100);
            font-size: 12px;
            font-family: SourceHanSansSC-regular;
        }

        .bottom {
            margin-top: 38px;
            color: rgba(121, 121, 121, 100);
            font-size: 12px;
            font-family: SourceHanSansSC-regular;

            div:nth-child(1) {
                display: flex;
                align-items: center;

                .tag {
                    margin-left: 14px;

                    .tagName {
                        margin-right: 6px;
                        margin-bottom: 10px;
                        font-size: 12px;
                    }
                }
            }

            div:nth-child(2) {
                margin-top: 20px;
                display: flex;
                align-items: center;

                .tag {
                    margin-left: 14px;

                    .tagName {
                        margin-right: 6px;
                        margin-bottom: 10px;
                        font-size: 12px;
                    }
                }
            }
        }

        .fold {
            text-align: center;
            color: rgba(137, 158, 192, 100);
            font-size: 12px;
            font-family: SourceHanSansSC-regular;
        }
    }

    .labelGroup {
        margin-top: 17px;
        position: relative;

        .labelGroup-items {
            padding: 15px;
            border-radius: 2px;
            font-size: 14px;
            font-family: Arial;
            //border: 1px solid rgba(187, 187, 187, 50);
            position: relative;
            margin-bottom: 20px;

            div:nth-child(1) {
                color: rgba(104, 128, 150, 100);
                font-size: 20px;
            }

            .labelGroup-items-title {
                color: rgba(51, 51, 51, 100);
                font-size: 20px;
                font-family: SourceHanSansSC-medium;
                margin-top: 21px;
                // margin-bottom: 20px;
            }

            div:nth-child(3) {
                .checkboxGroup {
                    padding-top: 16px;
                    // padding-bottom: 30px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

                    .checkbox-item {
                        width: 30%;
                        margin-bottom: 20px;
                        margin-right: 20px;
                    }
                }
            }

            .blankOptions {
                background-color: rgba(249, 249, 249, 100);
                padding: 9px 20px;
            }
        }
    }
}

.corner {
    position: absolute;
    top: -56px;
    right: -56px;
    width: 0;
    height: 0;
    border: 56px solid rgba(240, 246, 255, 100);
    border-bottom-color: transparent;
    border-top-color: transparent;
    border-left-color: transparent;
    transform: rotateZ(135deg);

    i {
        transform: rotateZ(-135deg);
        font-size: 24px;
        color: #156ED0;
        margin-top: -25px;
        margin-left: 15px;
    }
}

.zjfc {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #ff7200;
    font-size: 12px;
    text-align: center;
    color: #ff7200;
    margin-bottom: 10px;
}

.chrd {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #1faa53;
    font-size: 12px;
    text-align: center;
    color: #1faa53;
    margin-bottom: 10px;
}

.gqtz {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #1f88f9;
    font-size: 12px;
    text-align: center;
    color: #1f88f9;
    margin-bottom: 10px;
}

.jsjf {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #bf382f;
    font-size: 12px;
    text-align: center;
    color: #bf382f;
    margin-bottom: 10px;
}

.jrfc {
    width: 90px;
    height: 22px;
    line-height: 22px;
    border-radius: 3px;
    border: 1px solid #6cc2ed;
    font-size: 12px;
    text-align: center;
    color: #6cc2ed;
    margin-bottom: 10px;
}

.anchor {
    position: fixed;
    top: 20%;
    // right: 10%;
    color: rgba(99, 99, 99, 100);
    font-size: 14px;
    font-family: SourceHanSansSC-regular;
    display: flex;
    align-items: center;
    width: 120px;
}

.anchorList-items {
    margin-bottom: 14px;
    margin-left: 16px;
    border-radius: 50%;
}

.anchorList-items-options {
    margin-bottom: 14px;
    margin-left: 16px;
    border-radius: 50%;
    display: flex;
}

.anchor-circle {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid rgba(48, 120, 240, 100);
    display: inline-block;
    margin-right: 10px;
}

.item-middle-show {
    position: relative;
    left: -22px
}

.item-middle-hidden {
    position: relative;
    left: 24px
}

.item-top {
    position: relative;
    left: 24px
}

.item-bottom {
    position: relative;
    left: 24px
}

.top-circle {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .3);
    left: -3px;
    top: -7px;
}

.bottom-circle {
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, .3);
    left: -3px;
    bottom: -7px;
}

.folding {
    color: rgba(104, 128, 150, 100);
    font-size: 14px;
    font-family: PingFangSC-regular;
    text-align: center;
    margin-top: 26px;
    margin-bottom: 24px;
}

/deep/.el-checkbox__label {
    display: inline-grid !important;
    padding-left: 10px;
    line-height: 19px;
    font-size: 14px;
    white-space: pre-line;
    word-wrap: break-word;
    overflow: hidden;
    line-height: 26px
}

/deep/.el-checkbox {
    color: rgba(0, 0, 0, 0.6);
}

/deep/.el-checkbox__input.is-checked+.el-checkbox__label {
    color: #000;
}

.mandatory:before {
    content: "* ";
    color: red;
}

.remain {
    color: red;
    font-size: 12px;
    margin-right: 10px;
}

.hasas {
    color: gray;
    font-size: 12px;
    margin-right: 10px;
}

.spec-dialog /deep/.el-dialog__body {
    overflow-y: auto;
    height: 480px;
}

.span-label {
    // border-radius: 3px;
    background-color: rgba(227, 241, 255, 100);
    color: rgba(121, 148, 192, 100);
    font-size: 12px;
    text-align: center;
    font-family: Arial;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
}
</style>
